.slider__wrapper__hero__slide:hover img {
    transform: scale(1.02);
    transition: transform 0.3s ease-in-out;
}

.slider__wrapper__hero__slide img {
    transition: transform 0.3s ease-in-out;
}

.slider__wrapper__hero__slide {
    overflow: hidden;
}

.swiper-pagination-bullet {
    background-color: #ccc;
    opacity: 0.6;
    width: 12px;
    height: 12px;
}

.slider__wrapper__hero__slide {
    height: 100%;
}

.slider__wrapper__hero__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider__vote__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.slider__vote__thumbs {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.slider__vote__thumbs img {
    width: 20px;
    transform: scale(1);
    translate: 0.3s ease-in-out;
    cursor: pointer;
}

.slider__vote__thumbs img:hover {
    transform: scale(1.2);
    translate: 0.3s ease-in-out;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
    .slider__vote__container {
        flex-direction: column;
        width: 80%;
        gap: 1rem;
        margin-top: 5vh;
    }

    .slider__wrapper__hero__slide {
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    
    .slider__vote__container > * {
        text-align: center;
    }

    .slider__wrapper__hero__slide h1, .slider__wrapper__hero__slide h2, .slider__wrapper__hero__slide p {
        width: 90%;
    }
}