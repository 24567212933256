.mission__img__container img {
    transition: transform 0.5s ease-in-out;
  }
  
.mission__img__container img:hover {
    transform: rotate(3deg);
    transition: transform 0.5s ease-in-out;
}

@media (max-width: 768px) {
    .mission__container {
        width: 90%!important;
    }

    .mission__container p {
        text-align: justify!important;
    }

    .mission__img__container {
      width: 100%!important;
    }
}