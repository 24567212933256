.careers__img__container img {
    transition: transform 0.5s ease-in-out;
  }
  
.careers__img__container img:hover {
    transform: rotate(3deg);
    transition: transform 0.5s ease-in-out;
}

@media (max-width: 768px) {
    .careers__container p {
        text-align: justify!important;
    }

    .careers__img__container {
      width: 100%!important;
    }

    .careers__text__container {
      width: 100%!important;
    }
}