.teamviewer__expand__view__cta {
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    padding: '5vh 0 0 0';
}

@media (max-width: 768px) {
    .teamviewer__map {
        width: 100%!important;
        flex-wrap: wrap;

    }

    /* .teamviewer__map__member {
        max-width: 80%!important;
        width: 100%!important;
    } */

    .teamviewer__expand__view__cta span {
        font-family: "Monserrat", sans-serif!important;
        font-weight: 900!important;
    }

    .teamviewer__container__animated {
        width: 90%!important;
    }

    .teamviewer__expand__view__cta {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0!important;
    }
}