/* NavbarMenus.css */
.menu__item {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.5rem 1rem; */
    transition: all 0.3s ease-in-out;
    height: 100%;
    width: 120px;
}

.menu__item:hover {
    background-color: rgba(0,0,0,0.1);
}
