.aboutus__tile__container p {
    /* text-align: justify; */
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
}

.expandable-content {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 0px;
}

.expanded {
    max-height: 500px;
}

.expand-arrow {
    cursor: pointer;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    /* border-top: 20px solid rgb(94, 23, 235); */
    transition: transform 0.3s ease;
    transform: scale(1);
}

.expand-arrow.expanded {
    transform: rotate(180deg) scale(1);
}

.expand-arrow.expanded:hover {
    transform: rotate(180deg) scale(1.2);
}

.expand-arrow:hover {
    transform: scale(1.2);
}

@media screen and (max-width: 821px) {
    .aboutus__tile__container {
        width: 90%!important;
    }
}