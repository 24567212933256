.aboutus__expand__view__cta {
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    padding: '5vh 0 0 0';
}

.aboutus__wrapper p {
    font-size: 1rem;
}

.aboutus__overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #5C2EB666;
}

.aboutus__expand__view__cta span {
    font-family: "Monserrat", sans-serif!important;
    font-weight: 900!important;
}

@media screen and (max-width: 821px) {
    .aboutus__container {
        flex-direction: column!important;
        align-items: center!important;
    }
}