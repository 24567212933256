.system-page__container > * > p, .system-page__container > * > ul {
   text-align: left!important;
   width: 100%!important;
}

.system-page__container a {
   color: #541FBC!important;
   text-decoration: underline;
}

.system-page__container h3 {
   color: #541FBC!important;
   text-align: left!important;
   width: 100!important;
}

.system-page__container {
   text-align: left!important;
}