.typingEffect .cursor {
    animation: blink 0.7s infinite;
}

.typingEffect {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    opacity: 0.5;
}

  
  @keyframes blink {
    0%, 40% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes flash {
    0% {
      opacity: 0.5;
    }
    100% { opacity: 1; }
  }
  
  .flashEffect {
    animation: flash 0.4s forwards;
  }

  @media (max-width: 768px) {
    .typingEffect {
        display: block;
    }
}